import React from "react"
import styled from "styled-components"

type BadgeProps = {
  isPositive: boolean
  data: string
}

const BadgeStyles = styled.div<Pick<BadgeProps, "isPositive">>`
  ${props =>
    props.isPositive
      ? `
      background-color: var(--background-success);
      `
      : `
      background-color: red;
      `}
  color: white;
  padding: 0 12px;
  border-radius: 6px;
`

export default function Badge({ isPositive, data }: BadgeProps) {
  return (
    <BadgeStyles
      title={`${data} change since previous month`}
      isPositive={isPositive}
      className={`badge badge--${isPositive ? "positive" : "negative"}`}
    >
      <span>{data}</span>
    </BadgeStyles>
  )
}
