import React from "react"
import {
  LineChart,
  Line,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
  Legend,
} from "recharts"
import styled from "styled-components"
import {
  ExerciseMonthlyActivities,
  ExerciseMonthlyActivity,
} from "./ExerciseMonthlyActivity"

type LeadvilleGraphProps = {
  data: ExerciseMonthlyActivity[]
  yAxis: keyof ExerciseMonthlyActivities["running"]
  xAxis: keyof ExerciseMonthlyActivities
  activeIndex: number
  cumulativeData: ExerciseMonthlyActivities[]
}

const CustomizedDot = props => {
  // console.log(props)
  const { cx, cy, activeIndex, index } = props

  if (index === activeIndex) {
    return (
      <svg
        x={cx - 10}
        y={cy - 10}
        width={20}
        height={20}
        fill="green"
        viewBox="0 0 1024 1024"
      >
        <path d="M512 1009.984c-274.912 0-497.76-222.848-497.76-497.76s222.848-497.76 497.76-497.76c274.912 0 497.76 222.848 497.76 497.76s-222.848 497.76-497.76 497.76zM340.768 295.936c-39.488 0-71.52 32.8-71.52 73.248s32.032 73.248 71.52 73.248c39.488 0 71.52-32.8 71.52-73.248s-32.032-73.248-71.52-73.248zM686.176 296.704c-39.488 0-71.52 32.8-71.52 73.248s32.032 73.248 71.52 73.248c39.488 0 71.52-32.8 71.52-73.248s-32.032-73.248-71.52-73.248zM772.928 555.392c-18.752-8.864-40.928-0.576-49.632 18.528-40.224 88.576-120.256 143.552-208.832 143.552-85.952 0-164.864-52.64-205.952-137.376-9.184-18.912-31.648-26.592-50.08-17.28-18.464 9.408-21.216 21.472-15.936 32.64 52.8 111.424 155.232 186.784 269.76 186.784 117.984 0 217.12-70.944 269.76-186.784 8.672-19.136 9.568-31.2-9.12-40.096z" />
      </svg>
    )
  }

  return (
    <svg
      x={cx - 10}
      y={cy - 10}
      width={20}
      height={20}
      fill="green"
      viewBox="0 0 1024 1024"
    ></svg>
  )
}

const CustomToolTipStyles = styled.div`
  background-color: white;
  border-radius: 12px;
  padding: 12px;
  p,
  strong {
    margin: 0;

    text-transform: lowercase;
  }
  p:first-letter,
  strong:first-letter {
    text-transform: uppercase;
  }
  & > * + * {
    margin-top: 6px;
  }
`

const GraphWrapper = styled.div`
  height: 300px;
  @media only screen and (min-width: 1200px) {
    height: auto;
  }
`

const CustomTooltip = ({ cumulativeData, payload, label }) => {
  const [chartData] = payload
  const dataKey = chartData?.dataKey

  if (!dataKey) {
    return null
  }

  const currentMonthData = chartData.payload

  const currentMonthsCumulativeData = cumulativeData.find(
    data => data.month === String(label)
  )["running"][dataKey]

  return (
    <CustomToolTipStyles className="custom-tooltip">
      <strong>
        <p>{dataKey}</p>
      </strong>
      <p>
        {label}: {Number(currentMonthData[dataKey]).toLocaleString()}
      </p>
      <p>Cumulative: {Number(currentMonthsCumulativeData).toLocaleString()}</p>
    </CustomToolTipStyles>
  )
}

export function LeadvilleGraph({
  data,
  xAxis,
  yAxis,
  activeIndex,
  cumulativeData,
}: LeadvilleGraphProps) {
  return (
    <GraphWrapper>
      <ResponsiveContainer width="100%" height="100%">
        <LineChart
          data={data}
          margin={{ top: 5, right: 20, bottom: 5, left: 0 }}
        >
          <CartesianGrid stroke="#ccc" strokeDasharray="5 5" />
          <XAxis label={{ fill: "white" }} dataKey={xAxis} />
          <YAxis dataKey={yAxis} />
          <Tooltip
            content={<CustomTooltip cumulativeData={cumulativeData} />}
          />
          <Line
            type={"monotone"}
            dataKey={yAxis}
            stroke="#8884d8"
            activeDot={{ r: 8 }}
            dot={<CustomizedDot activeIndex={activeIndex} />}
          />
          <Legend verticalAlign="top" height={36} />
        </LineChart>
      </ResponsiveContainer>
    </GraphWrapper>
  )
}
