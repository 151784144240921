import React from "react";
import styled from 'styled-components';
import Img from "gatsby-image"
import { StaticQuery, graphql } from 'gatsby'

const StyledImg = styled(Img)`
    margin-top: var(--gap);
    margin-bottom: var(--gap);

`


export const PostImage = ({ imgPath, orientation, alt }) => {
    return (<StaticQuery
        query={graphql`query {
           landscape: allFile {
            nodes {
               id
               relativePath
               childImageSharp {
                  # Specify the image processing specifications right in the query.
                  # Makes it trivial to update as your page's design changes.
                  fluid(maxWidth: 800, quality: 80) {
                      # Choose either the fragment including a small base64ed image, a traced placeholder SVG, or one without.
                      ...GatsbyImageSharpFluid_noBase64
                  }
               }
            }
         }
         portrait: allFile {
            nodes {
               id
               relativePath
               childImageSharp {
                  # Specify the image processing specifications right in the query.
                  # Makes it trivial to update as your page's design changes.
                  fluid(maxWidth: 800, quality: 80) {
                      # Choose either the fragment including a small base64ed image, a traced placeholder SVG, or one without.
                      ...GatsbyImageSharpFluid_noBase64
                  }
               }
            }
         }
        }`}


        render={data => {
            let image;
            if (orientation === 'landscape') {
                image = data.landscape.nodes.filter(node => node.relativePath === imgPath)[0];
            } else {
                image = data.portrait.nodes.filter(node => node.relativePath === imgPath)[0];
            }


            return <StyledImg
                fluid={image.childImageSharp.fluid}
                alt={alt}
            />
        }} />)


}
