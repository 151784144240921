import React from "react"
import styled from "styled-components"

export const BoxEl = styled.div`
  border: 1px solid var(--color-border);
  padding: var(--space-m);
  background-color: var(--color-background);
  &[data-type="error"] {
    background-color: var(--background-error);
    border-color: var(--red);
  }
  &[data-type="success"] {
    background-color: var(--background-success);
    border-color: green;
    color: var(--color-on-dark);
  }
`

export function Box({ role, children }) {
  return (
    <BoxEl
      role={role ? "alert" : undefined}
      data-type={role ? role : undefined}
    >
      {children}
    </BoxEl>
  )
}
