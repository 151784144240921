import React, { useEffect, useReducer, useState } from "react"
import { useLocalStorage } from "../hooks/useLocalStorage"
import { Action } from "./Action"
import { GetIsUserLoggedIn } from "./actions"
import {
  LOG_OUT,
  SET_HAS_LOGIN_ERROR,
  SET_IS_LOGGED_IN,
  SET_IS_LOGGING_IN,
} from "./UserContext.consts"

export type UserContextState = {
  isLoggedIn: boolean
  isLoggingIn: boolean
  hasLoginError: boolean
}

export const initialValue: UserContextState = {
  isLoggedIn: false,
  isLoggingIn: false,
  hasLoginError: false,
}

export const UserContext = React.createContext(initialValue)

type UserContextProps = {
  children: React.ReactNode
}

export type UserContextProviderValue = {
  state: UserContextState
  dispatch: React.Dispatch<Action>
}

const reducer = (state: UserContextState, action: Action): UserContextState => {
  switch (action.type) {
    case SET_IS_LOGGED_IN:
      const isLoggedIn = action.data as boolean
      return {
        ...state,
        hasLoginError: false,
        isLoggingIn: false,
        isLoggedIn,
      }

    case SET_IS_LOGGING_IN:
      const isLoggingIn = action.data as boolean
      return {
        ...state,
        hasLoginError: false,
        isLoggedIn: false,
        isLoggingIn,
      }

    case SET_HAS_LOGIN_ERROR:
      const hasLoginError = action.data as boolean
      return {
        ...state,
        hasLoginError,
        isLoggingIn: false,
        isLoggedIn: false,
      }

    case LOG_OUT:
      return {
        ...state,
        hasLoginError: false,
        isLoggingIn: false,
        isLoggedIn: false,
      }

    default: {
      return state
    }
  }
}

export const UserContextProvider = (props: UserContextProps) => {
  const [isLoggedIn, setIsLoggedIn] = useState<boolean>(false)
  const [token] = useLocalStorage<string>("ss_token", "")
  const [state, dispatch] = useReducer(reducer, initialValue)
  const value = { isLoggedIn, setIsLoggedIn, state, dispatch }

  useEffect(() => {
    dispatch({
      type: SET_IS_LOGGING_IN,
      data: true,
    })

    const getUserIsLoggedIn = async (): Promise<void> => {
      try {
        let userIsLoggedIn = await GetIsUserLoggedIn(token)

        dispatch({
          type: SET_IS_LOGGED_IN,
          data: userIsLoggedIn,
        })
      } catch (error) {
        dispatch({
          type: SET_HAS_LOGIN_ERROR,
          data: true,
        })
      }
    }

    if (token && !isLoggedIn && !state.isLoggingIn) {
      getUserIsLoggedIn()
    }
  }, [])

  return (
    <UserContext.Provider value={value}>{props.children}</UserContext.Provider>
  )
}
