import React from "react"
import styled from "styled-components"

export const StackWrapper = styled.div`
  > * p,
  h1,
  h2,
  h3 {
    margin-bottom: 0;
  }
  > * + * {
    ${props =>
      props.topMarginSize
        ? `
            margin-top: var(--space-${props.topMarginSize})
        `
        : `
            margin-top: 1rem;
        `}
  }
  ${props =>
    props.justify
      ? `
        align-items: ${props.justify};
    `
      : `
        align-items: flex-start;
    `}
`
/**
 * @param  {} { topMarginSize the size of the top margin. Must be 's', 'm', 'l', 'xl', 'xxl' }
 * @param  {} =>(<StackWrapper{...props}>{children}</StackWrapper>
 * @desc Place children elements within the Stack to get even top margins
 */
const Stack = ({ topMarginSize, justify, children }) => (
  <StackWrapper topMarginSize={topMarginSize} justify={justify}>
    {children}
  </StackWrapper>
)

export default Stack
