import React, { useReducer } from "react"
import { MediaItemDto } from "../types/MediaItemDto"
import { Action } from "./Action"
import {
  openModal,
  SET_MEDIA_ITEMS,
  SET_MEDIA_ITEMS_LOADING,
  SET_MEDIA_ITEMS_LOADING_ERROR,
  SET_MODAL_ACTIVE,
  toggleModal,
} from "./AppContext.consts"

export type MediaJournal = {
  mediaItems: MediaItemDto[] | undefined
  isLoadingMediaItems: boolean
  hasError: boolean
}

export type State = {
  isModalOpen: boolean
  mediaJournal: MediaJournal
}

export const initialState: State = {
  isModalOpen: false,
  mediaJournal: {
    isLoadingMediaItems: false,
    mediaItems: undefined,
    hasError: false,
  },
}

export type AppContextProviderValue = {
  state: State
  dispatch: React.Dispatch<Action>
}

export const AppContext = React.createContext(initialState)

export const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case toggleModal:
      return {
        ...state,
        isModalOpen: !state.isModalOpen,
      }
    case openModal:
      return {
        ...state,
        isModalOpen: true,
      }
    case SET_MODAL_ACTIVE:
      const isModalOpen = action.data as boolean
      return {
        ...state,
        isModalOpen,
      }
    case SET_MEDIA_ITEMS:
      const mediaItems = action.data as MediaItemDto[]
      return {
        ...state,
        mediaJournal: {
          ...state.mediaJournal,
          hasError: false,
          isLoadingMediaItems: false,
          mediaItems,
        },
      }
    case SET_MEDIA_ITEMS_LOADING:
      const isLoadingMediaItems = action.data as boolean
      return {
        ...state,
        mediaJournal: {
          ...state.mediaJournal,
          hasError: false,
          isLoadingMediaItems,
        },
      }
    case SET_MEDIA_ITEMS_LOADING_ERROR:
      const hasError = action.data as boolean
      return {
        ...state,
        mediaJournal: {
          ...state.mediaJournal,
          isLoadingMediaItems: false,
          hasError,
        },
      }
    default:
      return state
  }
}

type Props = {
  children?: JSX.Element
}

export function AppContextProvider({ children }: Props) {
  const [state, dispatch] = useReducer(reducer, initialState)
  const value = { state, dispatch }

  return <AppContext.Provider value={value}>{children}</AppContext.Provider>
}
