import { Action } from "./Action"
import { State } from "./AppContext"
import { SET_MEDIA_ITEMS, SET_MEDIA_ITEMS_LOADING } from "./AppContext.consts"

export type GetIsUserLoggedInResponse = {
  isLoggedIn: boolean
}

export const GetIsUserLoggedIn = async (token: string): Promise<boolean> => {
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${token}`,
  }
  const result = await fetch("/api/GetIsUserLoggedIn", {
    method: "GET",
    headers,
  })

  const body = (await result.json()) as GetIsUserLoggedInResponse

  return body.isLoggedIn
}

export const GetMediaItems = async (
  state: State,
  dispatch: React.Dispatch<Action>,
  token: string
) => {
  dispatch({
    type: SET_MEDIA_ITEMS_LOADING,
    data: true,
  })

  const response = await fetch("./api/GetMediaItems", {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    method: "GET",
  })

  if (!response.ok) {
    throw new Error(response.statusText)
  }

  const values = await response.json()

  dispatch({
    type: SET_MEDIA_ITEMS,
    data: values,
  })
}
