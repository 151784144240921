import { StaticImage } from "gatsby-plugin-image"
import React from "react"

export default function LeavilleBodyPhoto({ month }: { month: string }) {
  const photoOptions = {
    transformOptions: {
      grayscale: true,
    },
    layout: "constrained",
  }

  switch (month) {
    case "January":
      return (
        <StaticImage
          {...photoOptions}
          placeholder="blurred"
          src={`../../markdown/leadville-training/body-jan.jpg`}
          alt="Leadville 100 trail run training photo January"
          layout="constrained"
        />
      )
    case "February":
      return (
        <StaticImage
          {...photoOptions}
          placeholder="blurred"
          src={`../../markdown/leadville-training/body-feb.jpg`}
          alt="Leadville 100 trail run training photo February"
          layout="constrained"
        />
      )
    case "March":
      return (
        <StaticImage
          {...photoOptions}
          placeholder="blurred"
          src={`../../markdown/leadville-training/body-march.jpg`}
          alt="Leadville 100 trail run training photo March"
          layout="constrained"
        />
      )
    case "April":
      return (
        <StaticImage
          {...photoOptions}
          placeholder="blurred"
          src={`../../markdown/leadville-training/body-april.jpg`}
          alt="Leadville 100 trail run training photo April"
          layout="constrained"
        />
      )
    case "May":
      return (
        <StaticImage
          {...photoOptions}
          placeholder="blurred"
          src={`../../markdown/leadville-training/body-may.jpg`}
          alt="Leadville 100 trail run training photo May"
          layout="constrained"
        />
      )
    case "June":
      return (
        <StaticImage
          {...photoOptions}
          placeholder="blurred"
          src={`../../markdown/leadville-training/body-june.jpg`}
          alt="Leadville 100 trail run training photo June"
          layout="constrained"
        />
      )
    case "July":
      return (
        <StaticImage
          {...photoOptions}
          placeholder="blurred"
          src={`../../markdown/leadville-training/body-july.jpg`}
          alt="Leadville 100 trail run training photo July"
          layout="constrained"
        />
      )
    case "August":
      return (
        <StaticImage
          {...photoOptions}
          placeholder="blurred"
          src={`../../markdown/leadville-training/body-aug.jpg`}
          alt="Leadville 100 trail run training photo August"
          layout="constrained"
        />
      )
    default:
      return null
  }
}
