import { LeadvilleProgress } from "@components/LeadvilleTrainingDashboard/LeadvilleProgress";
import { TwoColumnLayout } from "@components/TwoColumnLayout";
import { trainingData } from "../../../../src/markdown/leadville-training/trainingData";
import { getImage, GatsbyImage } from "gatsby-plugin-image";
import { StaticImage } from "gatsby-plugin-image";
import * as React from 'react';
export default {
  LeadvilleProgress,
  TwoColumnLayout,
  trainingData,
  getImage,
  GatsbyImage,
  StaticImage,
  React
};