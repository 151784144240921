import { ExerciseMonthlyActivities } from "../../components/LeadvilleTrainingDashboard/ExerciseMonthlyActivity"

export const trainingData: Record<string, ExerciseMonthlyActivities> = {
  January: {
    month: "January",
    running: {
      distance: 266,
      hours: 29,
      activities: 31,
    },
    gym: {
      hours: 5,
      activities: 5,
    },
  },
}

export const trainingDataArr: ExerciseMonthlyActivities[] = [
  {
    month: "January",
    running: {
      distance: 266,
      hours: 29,
      activities: 31,
      calories: 14086,
      ascent: 3383,
    },
    gym: {
      hours: 5,
      activities: 5,
    },
    photo: "body-jan.jpg",
  },
  {
    month: "February",
    running: {
      distance: 226,
      hours: 21,
      activities: 20,
      ascent: 3093,
      calories: 13177,
    },
    gym: {
      hours: 0,
      activities: 0,
    },
    photo: "body-feb.jpg",
  },
  {
    month: "March",
    running: {
      distance: 277,
      hours: 31,
      activities: 27,
      calories: 16603,
      ascent: 3710,
    },
    gym: {
      hours: 0,
      activities: 7,
    },
    photo: "body-march.jpg",
  },
  {
    month: "April",
    running: {
      distance: 293,
      hours: 29,
      activities: 23,
      calories: 20847,
      ascent: 4888,
    },
    gym: {
      hours: 0,
      activities: 0,
    },
    photo: "body-april.jpg",
  },
  {
    month: "May",
    running: {
      distance: 292,
      hours: 28,
      activities: 30,
      calories: 20426,
      ascent: 4929,
    },
    photo: "body-may.jpg",
    gym: {
      hours: 0,
      activities: 0,
    },
  },
  {
    month: "June",
    running: {
      distance: 344,
      hours: 34,
      activities: 24,
      calories: 23098,
      ascent: 6216,
    },
    photo: "body-june.jpg",
    gym: {
      hours: 0,
      activities: 0,
    },
  },
  {
    month: "July",
    running: {
      distance: 423,
      hours: 43,
      activities: 28,
      ascent: 5958,
      calories: 27880,
    },
    photo: "body-july.jpg",
    gym: {
      hours: 0,
      activities: 0,
    },
  },
  {
    month: "August",
    photo: "body-aug.jpg",
    running: {
      distance: 245,
      hours: 34,
      activities: 15,
      calories: 16596,
      ascent: 5656,
    },
    gym: {
      hours: 0,
      activities: 0,
    },
  },
]
