import React from "react"
import styled from "styled-components"

type TwoColumnLayoutProps = {
  col1: React.ReactElement
  //   col2: React.ReactElement
  children: React.ReactNode
}

const StyledTwoColumnLayout = styled.div``

export function TwoColumnLayout({ col1, children }: TwoColumnLayoutProps) {
  console.log(children)
  return (
    <StyledTwoColumnLayout>
      {col1 && col1}
      <div className="poo"></div>
      {children}
    </StyledTwoColumnLayout>
  )
}
