import { graphql, StaticQuery } from 'gatsby';
import Img from "gatsby-image";
import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';
gsap.registerPlugin(ScrollTrigger);



const StackContainer = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: var(--gap);
    margin-bottom: var(--gap);
`

const StyledImg = styled(Img)`
    display: block;
`

const Item = styled.a`
    width: 50%;
    text-align: center;
    transform: scale(1);
    transition: transform 0.25s ease;
    @media (min-width: 992px) {
        width: 33%;
    }
    img {
        width: 100%;
    }
    &:hover {
        transform: scale(1.05);
        transition: transform 0.25s ease;
        text-decoration: none;
    }
`

const StackItem = React.forwardRef(({ item, images }, ref) => {
    const itemRef = useRef(null);
    let image = images
        .images.nodes.filter(image => {
            return image.relativePath === item.logoPath
        })

    useEffect(() => {
        ref.current = [...ref.current, itemRef.current];
    }, [itemRef, ref])

    return (
        <Item ref={itemRef} href={item.link} target="_blank" title={`External link to ${item.name} website`}>
            <StyledImg
                fluid={image[0].childImageSharp.fluid}
                objectFit="cover"
                title={`${item.name} logo`}
                objectPosition="50% 50%"
                alt={`${item.name} logo`} />
            <small>{item.purpose}</small>
        </Item>
    )
})
/**
 * @desc  Used to display logos of tech items. Pass in an array
 *  of elements
 * @param stack
 * @param /{ name: string, purpose: string, logoPath: string}[]
 */
const TechStack = ({ stack }) => {
    const itemsRef = useRef([]);

    useEffect(() => {
        let delay = 0;
        itemsRef.current.forEach(item => {
            gsap.from(item, { autoAlpha: 0, delay: delay, y: '20px', scrollTrigger: {
                trigger: item,
                start: 'top 90%',
            }})
            delay += 0.15;
        })
    }, [itemsRef]);

    return (<StaticQuery
        query={graphql`query {
            images: allFile(filter: {relativeDirectory: {eq: "logos"}}) {
                nodes {
                   id
                   relativePath
                   childImageSharp {
                      # Specify the image processing specifications right in the query.
                      # Makes it trivial to update as your page's design changes.
                      fluid(maxWidth: 250) {
                        ...GatsbyImageSharpFluid
                      }
                   }
                }
             }
         }`}


        render={data => {
            return (
                <StackContainer>
                    {stack.map((item, i) => (
                        <StackItem ref={itemsRef} key={i} images={data} item={item} />
                    ))}
                </StackContainer>)
        }} />)

}

export default TechStack;