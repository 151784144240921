import React from "react"
import styled from "styled-components"

const StyledSlider = styled.input`
  width: 100%;
`

export type SliderProps = {
  activeMonthIndex: number
  changeActiveMonth: (i: number) => void
}

export const Slider = ({
  activeMonthIndex,
  changeActiveMonth,
}: SliderProps) => {
  const handleChange = (e: any) => {
    const monthIndex = e.target.value

    changeActiveMonth(Number(monthIndex))
  }

  return (
    <StyledSlider
      type="range"
      min="0"
      max="7"
      step="1"
      value={activeMonthIndex}
      onChange={handleChange}
    />
  )
}
