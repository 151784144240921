import styled from "styled-components"

export const LeadvilleTrainingDashboardStyles = styled.div`
  width: 100%;
  background-color: #333836;
  width: 100%;
  padding: 18px;
  border-radius: 18px;
  display: grid;
  gap: 2rem;
  grid-template-columns: 1fr;
  grid-template-rows: repeat(4, auto);
  border: 3px solid #111111;
  .first-row {
    grid-row: 1/2;
  }
  .second-row {
    grid-row: 2/3;
  }
  .third-row {
    grid-row: 3/4;
  }
  .fourth-row {
    grid-row: 4/5;
  }
`
