import styled from "styled-components"

export const DashboardFourthRow = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr;
  @media only screen and (min-width: 1200px) {
    grid-template-columns: 1fr 3fr;
  }
`
