import { StaticImage } from "gatsby-plugin-image"
import React, { useEffect, useReducer } from "react"
import styled from "styled-components"
import { BoxEl } from "./Box"
import LoadingIndicator from "./LoadingIndicator"
import { StackWrapper } from "./Stack/Stack"
import { PulseBlob } from "./PulseBlob"

const SteamDeckStatusElement = styled(BoxEl)`
  background: var(--color-border);
  color: var(--color-on-dark);
  a {
    color: var(--color-on-dark);
  }
  display: flex;
  flex-direction: column;
  max-width: 30rem;
  p {
    margin-bottom: 0;
  }
`

const ReservationStatusContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
`

const LOADING_START = "loadingStart"
const LOADING_SUCCESS = "loadingSuccess"
const ERROR = "error"

const initialState = {
  isLoading: false,
  hasError: false,
  steamDeckStatus: false,
}

const reducer = (state, action) => {
  switch (action.type) {
    case LOADING_START:
      return {
        ...state,
        hasError: false,
        isLoading: true,
      }
    case LOADING_SUCCESS:
      return {
        steamDeckStatus: action.payload,
        hasError: false,
        isLoading: false,
      }
    case ERROR:
      return {
        ...state,
        hasError: true,
        isLoading: false,
      }
    default:
      throw new Error()
  }
}

const LoadingStatus = () => (
  <>
    <LoadingIndicator onDarkBackground={true} />
  </>
)

const ReservationStatus = ({ canBeReserved }) => (
  <ReservationStatusContainer>
    <p>
      <a
        href="https://store.steampowered.com/steamdeck"
        rel="noreferrer"
        target="_blank"
      >
        Steam Deck
      </a>{" "}
      {canBeReserved ? "can" : "can't"} be reserved in Australia
    </p>
    <PulseBlob
      title={
        canBeReserved
          ? "Ready to reserve Steam Deck"
          : "Cannot reserve the Steam Deck"
      }
      className={canBeReserved ? "green" : "red"}
    ></PulseBlob>
  </ReservationStatusContainer>
)

export const SteamDeckStatus = () => {
  const [state, dispatch] = useReducer(reducer, initialState)

  useEffect(() => {
    fetchSteamDeckStatus()
  }, [])

  const fetchSteamDeckStatus = async () => {
    try {
      dispatch({
        type: LOADING_START,
      })
      const status = await fetch("/api/get-steam-deck-status")
      const body = await status.json()
      dispatch({
        type: LOADING_SUCCESS,
        payload: body.canBeReserved,
      })
    } catch (error) {
      dispatch({
        type: ERROR,
      })
    }
  }

  return (
    <SteamDeckStatusElement className="steam-deck-status">
      <StackWrapper>
        <StaticImage
          src="../images/steam-deck-logo.png"
          alt="steam deck logo"
          layout="fixed"
          height={50}
        ></StaticImage>
        {state.isLoading ? (
          <LoadingStatus />
        ) : (
          <ReservationStatus canBeReserved={state.steamDeckStatus} />
        )}
      </StackWrapper>
    </SteamDeckStatusElement>
  )
}
