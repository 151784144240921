import React, { useMemo } from "react"
import styled from "styled-components"
import Badge from "./Badge"
import { ExerciseMonthlyActivity } from "./ExerciseMonthlyActivity"

type TrainingTileProps = {
  current: number
  previous: number
  description: string
  changeDataType: (d: keyof ExerciseMonthlyActivity) => void
  dataType: keyof ExerciseMonthlyActivity
}

const TrainingTileStyles = styled.button`
  background-color: white;
  border-radius: 18px;
  display: grid;
  grid-template-rows: repeat(3, minmax(22px, auto));
  grid-template-columns: 1fr auto;
  color: black;
  padding: 12px;
  grid-row-gap: 18px;
  grid-column-gap: 12px;
  transition: all 0.25s ease;
  filter: drop-shadow(0 0 0.5rem #000000);
  &:hover {
    background-color: #0000003e;
    color: white;
    transition: all 0.25s ease;
  }
  cursor: pointer;
  .percentChange {
    grid-column: 2/3;
    grid-row: 1/2;
    justify-self: end;
  }
  .description {
    grid-column: 1/3;
    grid-row: 3/4;
    align-self: end;
    justify-self: end;
  }
  .current {
    grid-column: 1/3;
    grid-row: 2/3;
    align-self: center;
    justify-self: center;
    font-size: 1.5rem;
  }
`

export default function TrainingTile({
  current,
  previous,
  description,
  changeDataType,
  dataType,
}: TrainingTileProps) {
  const percentageChange = useMemo(() => {
    let percent
    if (current !== 0) {
      if (previous !== 0) {
        percent = ((current - previous) / previous) * 100
      } else {
        percent = current * 100
      }
    } else {
      percent = -previous * 100
    }
    return `${percent.toFixed(0)}%`
  }, [current, previous])

  const isPositiveChange = useMemo(() => current > previous, [
    current,
    previous,
  ])

  const shouldDisplayBadge = useMemo(() => {
    return percentageChange === "0%" ? false : true
  }, [percentageChange])

  const currentFormatted = useMemo(
    () => (current ? current.toLocaleString() : null),
    [current]
  )

  const handleClick = (dataType: string) => {
    const data = getDataType(dataType)
    changeDataType(data)
  }

  const getDataType = (dataType: string) =>
    dataType.split(" ")[0].toLowerCase() as keyof ExerciseMonthlyActivity

  const isActiveDataType = useMemo(() => {
    return getDataType(description) === dataType
  }, [dataType])

  return (
    <TrainingTileStyles onClick={() => handleClick(description)}>
      {shouldDisplayBadge && (
        <span className="percentChange">
          <Badge isPositive={isPositiveChange} data={percentageChange}></Badge>
        </span>
      )}
      <span className="current">{currentFormatted}</span>
      <span className="description">
        {isActiveDataType && <span title="Currently graphed">📈</span>}
        {description}
      </span>
    </TrainingTileStyles>
  )
}
