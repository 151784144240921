export const nextStack =
    [
        {
            "name": "Contentful",
            "purpose": "Headless CMS",
            "logoPath": "logos/logo_contentful.png"
        },
        {
            "name": "NextJS",
            "purpose": "Front end and Serverless functions",
            "logoPath": "logos/logo_next.png"
        },
        {
            "name": "Netlify",
            "purpose": "Netlify hosting",
            "logoPath": "logos/logo_netlify.png"

        }
    ];
