import React from "react"
import { AppContextProvider } from "./src/contexts/AppContext"
import { UserContextProvider } from "./src/contexts/UserContext"
import "./src/styles/global.css"
require("prismjs/themes/prism-solarizedlight.css")

export const wrapRootElement = ({ element }: { element: React.ReactNode }) => {
  return (
    <AppContextProvider>
      <UserContextProvider>{element}</UserContextProvider>
    </AppContextProvider>
  )
}
