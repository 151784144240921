import React, { useCallback, useEffect, useMemo, useState } from "react"
import { trainingDataArr } from "../../markdown/leadville-training/trainingData"
import { ExerciseMonthlyActivities } from "./ExerciseMonthlyActivity"
import { StaticImage } from "gatsby-plugin-image"
import { ExerciseMonthlyActivity } from "./ExerciseMonthlyActivity"
import LeavilleBodyPhoto from "./LeadvilleBodyPhoto"
import { LeadvilleTrainingDashboardStyles } from "./LeadvilleTrainingDashboardStyles"
import { DashboardHeading } from "./LeadvilleDashboardHeading"
import { DashboardSecondRow } from "./DashboardSecondRow"
import { DashboardThirdRow } from "./DashboardThirdRow"
import { Slider } from "./Slider"
import { DashboardFourthRow } from "./DashboardFourthRow"
import TrainingTile from "./TrainingTile"
import RunningTrainingTiles from "./RunningTrainingTiles"
import { LeadvilleGraph } from "./LeadvillGraph"

const sum = (a: number | undefined, b: number | undefined) => {
  if (a === undefined || b === undefined) {
    return 0
  }

  return a + b
}

export function LeadvilleProgress() {
  const [activeMonthIndex, setActiveMonthIndex] = useState(0)
  const [activeGraphedDataType, setActiveGraphedDataType] = useState<
    keyof ExerciseMonthlyActivity
  >("distance")

  const calculateCumulativeData = useCallback(
    (trainingData: ExerciseMonthlyActivities[]) => {
      return trainingData.reduce(
        (accumulator, currentValue) => {
          return {
            ...currentValue,
            running: {
              distance: sum(
                currentValue?.running?.distance,
                accumulator?.running?.distance
              ),
              hours: sum(
                currentValue?.running?.hours,
                accumulator?.running?.hours
              ),
              activities: sum(
                currentValue?.running?.activities,
                accumulator?.running?.activities
              ),
              calories: sum(
                currentValue?.running?.calories,
                accumulator?.running?.calories
              ),
              ascent: sum(
                currentValue?.running?.ascent,
                accumulator?.running?.ascent
              ),
            },
            gym: {
              hours: sum(currentValue?.gym?.hours, accumulator?.gym?.hours),
              activities: sum(
                currentValue?.gym?.activities,
                accumulator?.gym?.activities
              ),
            },
          }
        },
        {
          running: {
            distance: 0,
            hours: 0,
            activities: 0,
            calories: 0,
            ascent: 0,
          },
          gym: {
            hours: 0,
            activities: 0,
          },
        } as ExerciseMonthlyActivities
      )
    },
    []
  )

  const cumulativeData = useMemo(() => {
    const trainingData = trainingDataArr
    const numberOfMonths = trainingData.length

    return trainingData.map((month, i, arr) => {
      // Slice the array to the current month
      const previousMonths = arr.slice(0, i + 1)
      console.log(previousMonths)

      // Return the totals for the sliced array
      const summedData = calculateCumulativeData(previousMonths)
      console.log(summedData)

      // continue

      return summedData
    })
  }, [])

  console.log(cumulativeData)

  const currentlySelectedMonthData = useMemo(() => {
    return trainingDataArr[activeMonthIndex]
  }, [activeMonthIndex])

  const previouslySelectedMonthData = useMemo(() => {
    return activeMonthIndex === 0
      ? trainingDataArr[activeMonthIndex]
      : trainingDataArr[activeMonthIndex - 1]
  }, [activeMonthIndex])

  const shouldDisplayPhoto = useMemo(() => {
    return !!currentlySelectedMonthData.photo
  }, [currentlySelectedMonthData])

  const currentGraphData = useMemo(() => {
    return trainingDataArr.map(d => ({
      month: d.month,
      ...d.running,
    }))
  }, [currentlySelectedMonthData])

  const handleChangeActiveGraphedDataType = (
    dataType: keyof ExerciseMonthlyActivity
  ) => {
    setActiveGraphedDataType(dataType)
  }

  return (
    <LeadvilleTrainingDashboardStyles>
      <div className="first-row">
        <DashboardHeading>{currentlySelectedMonthData.month}</DashboardHeading>
      </div>
      <DashboardSecondRow className="second-row">
        <Slider
          activeMonthIndex={activeMonthIndex}
          changeActiveMonth={setActiveMonthIndex}
        />
      </DashboardSecondRow>
      <DashboardThirdRow className="third-row">
        <RunningTrainingTiles
          currentData={currentlySelectedMonthData?.running}
          previousData={previouslySelectedMonthData?.running}
          changeDataType={setActiveGraphedDataType}
          activeGraphedDataType={activeGraphedDataType}
        ></RunningTrainingTiles>
      </DashboardThirdRow>
      <DashboardFourthRow className="fourth-row">
        {shouldDisplayPhoto && (
          <LeavilleBodyPhoto month={currentlySelectedMonthData.month} />
        )}
        <LeadvilleGraph
          data={currentGraphData}
          yAxis={activeGraphedDataType}
          xAxis={"month"}
          activeIndex={activeMonthIndex}
          cumulativeData={cumulativeData}
        ></LeadvilleGraph>
      </DashboardFourthRow>
    </LeadvilleTrainingDashboardStyles>
  )
}
