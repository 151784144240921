import React from "react"
import styled from "styled-components"
import {
  ExerciseMonthlyActivities,
  ExerciseMonthlyActivity,
} from "./ExerciseMonthlyActivity"
import TrainingTile from "./TrainingTile"

const RunningTrainingTilesStyles = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: auto 1fr;
  h3 {
    grid-row: 1 / 2;
    color: white;
  }
  .tiles {
    grid-row: 2 / 3;
    grid-column: 1 / 5;
    display: flex;
    gap: 12px;
    flex-wrap: wrap;
    & > * {
      width: calc(50% - 6px);
      @media only screen and (min-width: 1200px) {
        flex: 1;
        width: 100%;
      }
    }
  }
`

type RunningTrainingTilesProps = {
  currentData: ExerciseMonthlyActivities["running"]
  previousData: ExerciseMonthlyActivities["running"]
  changeDataType: (d: keyof ExerciseMonthlyActivity) => void
  activeGraphedDataType: keyof ExerciseMonthlyActivity
}

export default function RunningTrainingTiles({
  currentData,
  previousData,
  changeDataType,
  activeGraphedDataType,
}: RunningTrainingTilesProps) {
  return (
    <RunningTrainingTilesStyles>
      <h3>Running 🏃‍♂️</h3>
      <div className="tiles">
        <TrainingTile
          current={currentData.activities}
          previous={previousData.activities}
          description={"Activities"}
          changeDataType={changeDataType}
          dataType={activeGraphedDataType}
        ></TrainingTile>
        <TrainingTile
          current={currentData.hours}
          previous={previousData.hours}
          description={"Hours"}
          changeDataType={changeDataType}
          dataType={activeGraphedDataType}
        ></TrainingTile>
        <TrainingTile
          current={currentData.distance}
          previous={previousData.distance}
          description={"Distance (km)"}
          changeDataType={changeDataType}
          dataType={activeGraphedDataType}
        ></TrainingTile>
        <TrainingTile
          current={currentData.ascent}
          previous={previousData.ascent}
          description={"Ascent (m)"}
          changeDataType={changeDataType}
          dataType={activeGraphedDataType}
        ></TrainingTile>
        <TrainingTile
          current={currentData.calories}
          previous={previousData.calories}
          description={"Calories"}
          changeDataType={changeDataType}
          dataType={activeGraphedDataType}
        ></TrainingTile>
      </div>
    </RunningTrainingTilesStyles>
  )
}
