
export const stack =
    [
        {
            "name": "Gatsby",
            "purpose": "Front end (React/ Gatsby)",
            "logoPath": "logos/logo_gatsby.png"
        },
        {
            "name": "Styled Components & CSS Variables",
            "purpose": "Styled Components",
            "logoPath": "logos/logo_styled-comp.png"

        },
        {
            "name": "Twitter API",
            "purpose": "Tweet API to retrieve data",
            "logoPath": "logos/logo_twitter.png"

        },
        {
            "name": "Instagram API",
            "purpose": "Instagram API to retrieve data",
            "logoPath": "logos/logo_insta.png"

        },
        {
            "name": "AWS Lambda Serverless Functions",
            "purpose": "Creating serverless API endpoints to fetch data from Twitter and Instagram",
            "logoPath": "logos/logo_aws.png"

        },
        {
            "name": "Netlify",
            "purpose": "Netlify hosting",
            "logoPath": "logos/logo_netlify.png"

        }

    ];
